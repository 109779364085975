/**
 * 环境配置
 *
 * @type {string}
 */

let baseUrl = 'http://127.0.0.1';

let protocol = location.protocol.includes("https") ? 'https' : 'http';

if (process.env.NODE_ENV === "development") {
    console.log(process.env.NODE_ENV)
    baseUrl = protocol + "://127.0.0.1:28095";
} else if (process.env.NODE_ENV === "preview") {
    console.log(process.env.NODE_ENV)
    baseUrl = protocol + "://music.scoder.club";
} else if (process.env.NODE_ENV === "test") {
    console.log(process.env.NODE_ENV)
    baseUrl = protocol + "://music.scoder.club";
} else if (process.env.NODE_ENV === "production") {
    baseUrl = protocol + "://music.scoder.club";
}

let isProduction = false;

if (process.env.NODE_ENV === "production") {
    isProduction = true;
}

export {
    baseUrl,
    isProduction
}
