export const SHARE_TEMPLATES = [
    `✨ Jusic 点歌台\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🔗 房间链接：{url}`,
    `🎵 Jusic 点歌台\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n✨ 快来一起听歌吧！\n🔗 点击加入：{url}`,
    `✨ Jusic 点歌台 ✨\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎉 快来一起听歌吧！\n🔗 点击加入：{url}`,
    `🎵 Jusic 点歌台邀请你 🎵\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎧 加入我们一起享受音乐！\n🔗 房间链接：{url}`,
    `✨ Jusic 点歌台邀请你 ✨\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎧 加入我们一起享受音乐！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台邀请你 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎧 加入我们一起享受音乐！\n🔗 房间链接：{url}`,
    `🎵 Jusic 点歌台温馨提醒 🎵\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎉 音乐让生活更美好，快来加入我们吧！\n🔗 房间链接：{url}`,
    `✨ Jusic 点歌台温馨提醒 ✨\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎧 音乐让生活更美好，快来加入我们吧！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台温馨提醒 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n✨ 音乐让生活更美好，快来加入我们吧！\n🔗 房间链接：{url}`,
    `🎵 Jusic 点歌台 | 音乐不停歇 🎵\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎉 你的音乐伙伴已上线，快来一起嗨！\n🔗 房间链接：{url}`,
    `✨ Jusic 点歌台 | 音乐不停歇 ✨\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🎵 你的音乐伙伴已上线，快来一起嗨！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n✨ 你的音乐伙伴已上线，快来一起嗨！\n🔗 房间链接：{url}`,
    `🎊 Jusic 点歌台 | 音乐不停歇 🎊\n🎶 正在播放：《{musicName}》 - {musicArtist}\n😊 让音乐点亮你的心情！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🥳 点你喜欢的歌，大家一起听！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🥳 同步播放，共享音乐时光！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n💬 边听边聊，音乐社交新体验！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n💬 点歌、聊天、共享音乐时光！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n💬 同步播放，点歌互动，音乐不停！\n🔗 房间链接：{url}`,
    `🎉 Jusic 点歌台 | 音乐不停歇 🎉\n🎶 正在播放：《{musicName}》 - {musicArtist}\n💬 点歌互动，同步播放，音乐共享！\n🔗 房间链接：{url}`,
    `🔮 Jusic 点歌台 | 神秘音乐盒 🔮\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🤓 探索未知的音乐世界！\n🔗 房间链接：{url}`,
    `🎁 Jusic 点歌台 | 特别音乐礼物 🎁\n🎶 正在播放：《{musicName}》 - {musicArtist}\n🤩 快来领取你的音乐惊喜！\n🔗 房间链接：{url}`,
];

export const ELSE_TEST = [];



