import store from "@/store/index";
import {log} from "@/utils/log";

// 音乐相关接口
export const music = {
    pick(data) {
        log.debug('music.pick', data);
        return sendMessage('/music/pick', {
            id: data.musicId || data.id,
            name: data.name,
            sendTime: Date.now()
        });
    },
    skipVote() {
        return sendMessage('/music/skip/vote', {
            sendTime: Date.now()
        });
    },
    top(id) {
        return sendMessage('/music/top', {
            id: id,
            sendTime: Date.now()
        })
    },
    delete(id) {
        return sendMessage('/music/delete', {
            id: id,
            sendTime: Date.now()
        })
    },
    blackSubmit(id) {
        return sendMessage('/music/black', {
            id: id,
            sendTime: Date.now()
        })
    },
    blackCancel(id) {
        return sendMessage('/music/unblack', {
            id: id,
            sendTime: Date.now()
        })
    },
    search(data) {
        data.sendTime = Date.now();
        log.debug('music.search', data);
        return sendMessage('/music/search', data)
    },
}

// 聊天相关接口
export const chat = {
    send(message) {
        log.debug('chat.send', message);
        return sendMessage('/chat', {
            content: message,
            sendTime: Date.now()
        })
    },
    blackSubmit(session) {
        return sendMessage('/chat/black', {
            sessionId: session,
            sendTime: Date.now()
        })
    },
    blackCancel(session) {
        return sendMessage('/chat/unblack', {
            sessionId: session,
            sendTime: Date.now()
        })
    },
    pictureSearch(data) {
        return sendMessage('/chat/picture/search', data)
    },
}

// 登录相关接口
export const auth = {
    root(password) {
        return sendMessage('/auth/root', {
            password: password,
            sendTime: Date.now()
        })
    },
    admin(password) {
        return sendMessage('/auth/admin', {
            password: password,
            sendTime: Date.now()
        })
    },
}

// 设置相关接口
export const setting = {
    name(nickname) {
        return sendMessage('/setting/name', {
            name: nickname,
            sendTime: Date.now()
        })
    },
}

// 邮件相关接口
export const mail = {
    send(message) {
        return sendMessage('/mail/send', {
            content: message,
            sendTime: Date.now()
        })
    },
}

// 歌单相关接口
export const playlist = {
    update() {
        return sendMessage('/playlist/refresh', {})
    },
    modify(id) {
        return sendMessage('/playlist/modify', {
            id: id,
            sendTime: Date.now()
        })
    },
    append(data) {
        log.debug('playlist.append', data);
        const params = {
            // sessionId: null,
            clientId: window.localStorage.getItem('CLIENT_ID'),
            // roomId: null,
            songId: data.songId,
            songName: data.songName,
            songArtist: data.songArtist,
            songAlbum: data.songAlbum,
            // songLyric: null,
            // songPictureUrl: null,
            songDuration: data.songDuration,
            // type: 1,
            channel: data.channel,
            sendTime: Date.now()
        }
        return sendMessage('/playlist/append', params)
    },
    simple() {
        return sendMessage('/playlist/simple')
    },
    detail() {
        return sendMessage('/playlist/detail')
    },
}

export const wsClient = {
    music,
    chat,
    auth,
    setting,
    mail,
    playlist,
}

// 发送消息的通用方法
function sendMessage(destination, data) {
    return new Promise((resolve, reject) => {
        try {
            const stompClient = store.getters.getStompClient;
            if (!stompClient || !stompClient.connected) {
                throw new Error('STOMP client is not connected');
            }

            let result = stompClient.send(destination, {}, JSON.stringify(data));
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
}



