<template>
    <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
               :fullscreen="fullscreen"
               :open.sync="params.openDialog"
               class="music-history-dialog-wrapper">
        <div class="music-history-container">
            <div class="music-history-head"></div>
            <div class="music-history-body">
                <mu-data-table id="dialogDataTable" style="background-color: transparent" :selectable="false"
                               :hover="false"
                               :height="tableHeight"
                               :columns="columns" :data="dataList">
                    <template slot-scope="scope">
                        <td class="is-left">{{ scope.$index + 1 }}</td>

                        <td v-if="fullscreen" class="is-center">
                            <a class="search_pick_btn_m" @click="pickMusic(scope.row)">点歌</a>
                        </td>

                        <td class="is-left">{{ scope.row.name }}</td>
                        <td class="is-center">{{ scope.row.artist }}</td>
                        <td class="is-center">{{ scope.row.times }}</td>

                        <td v-if="!fullscreen" class="is-center">
                            <a class="search_pick_btn" @click="pickMusic(scope.row)">点歌</a>
                        </td>
                        <td class="is-center">
                            <a :class="fullscreen ? 'search_pick_btn_m' : 'search_pick_btn'" @click="deleteMusic(scope.row)">删除</a>
                        </td>
                    </template>
                </mu-data-table>
            </div>
            <div class="music-history-foot">
                <mu-flex justify-content="center">
                    <mu-pagination :total="page.total" :current.sync="page.current" :page-count="page.count"
                                   :page-size="page.limit" @change="paginationChange"></mu-pagination>
                </mu-flex>
            </div>
            <div class="music-history-foot">
                <div style="display: flex; flex-direction: column;">
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
                            <mu-icon value="cancel"></mu-icon>
                        </mu-button>
                    </div>
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
                    </div>
                </div>
            </div>
        </div>
    </mu-dialog>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import {timeUtils} from "../utils";
import {music} from "../api/music.js";
import {wsClient} from "@/api/socketClient";
import {del} from "vue";

export default {
    name: "MusicHistory",
    props: {
        params: {
            openDialog: false
        },
    },
    computed: {
        ...mapGetters({
            isRoot: 'isSocketRoot',
            isAdmin: 'isSocketAdmin',
        }),
        ...mapMutations({}),
    },
    data: () => {
        return {
            fullscreen: true,
            screenWidth: document.documentElement.clientWidth,
            tableHeight: 400,
            dialogShow: false,

            keyword: null,
            columns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', width: 150, align: 'center'},
                {title: '次数', name: 'times', align: 'center'},
                {title: '操作', name: 'op', width: 120, align: 'center'},
                {title: '清理', name: 'op', width: 120, align: 'center'},
            ],
            fullScreenColumns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '操作', name: 'op', width: 120, align: 'center'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', width: 150, align: 'center'},
                {title: '次数', name: 'times', align: 'center'},
                {title: '清理', name: 'times', align: 'center'},
            ],
            normalColumns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', width: 150, align: 'center'},
                {title: '次数', name: 'times', align: 'center'},
                {title: '操作', name: 'op', width: 120, align: 'center'},
                {title: '清理', name: 'op', width: 120, align: 'center'},
            ],
            dataList: [],
            page: {
                current: 1,
                limit: 10,
                count: 7,
                total: 0
            }
        }
    },
    methods: {
        del,
        updateSearchKeyword: function (value) {
            this.keyword = value;
        },
        search: function () {
            const _this = this;
            music.getTotalCount().then(total => {
                if (total && total > 0) {
                    let pageIndex = this.page.current;
                    let pageSize = this.page.limit;
                    _this.page.total = total;
                    music.getPageData(pageIndex, pageSize, true).then(pageDataList => {
                        _this.dataList = pageDataList;
                    });
                }
            });
        },
        paginationChange: function (page) {
            this.page.current = page;
            this.search();
        },
        showPickButton(value) {
            return true;
        },
        pickMusic: function (row) {
            wsClient.music.pick(row);

            if (this.isRoot || this.isAdmin) {
                this.$toast.message(`[${row.id}]${row.name} - 已发送点歌请求`);
            } else {
                this.$toast.message(`${row.name} - 已发送点歌请求`);
            }
        },
        deleteMusic: function(row) {
            const _this = this;
            music.delete(row).then(res => {
                _this.$toast.success('已删除：' + row.name + ' - ' + row.artist);
                _this.search();
            })
        },
        formatterTime: function (value) {
            return timeUtils.secondsToHH_mm_ss(value)
        },

        getScreenWidth: function () {
            return document.documentElement.clientWidth;
        },

        handleResize: function () {
            this.screenWidth = document.documentElement.clientWidth;
            if (this.screenWidth <= 800) {
                this.fullscreen = true;
                this.columns = this.fullScreenColumns;
                this.tableHeight = document.documentElement.clientHeight - 64 - 72;
            } else {
                this.fullscreen = false;
                this.columns = this.normalColumns;
                this.tableHeight = document.documentElement.clientHeight - 64 - 72 - 24 - 100;
            }
        },

        resetDialogFullscreen: function () {
            this.dialogShow = false;
            if (this.fullscreen) {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.add("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.add("b");
                    }

                    this.dialogShow = true;
                }, 10);
            } else {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.remove("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.remove("b");
                    }

                    this.dialogShow = true;
                }, 10);
            }
        }
    },
    created() {
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.keyword = null;
        this.page.current = 1;
        this.page.limit = 10;
        this.page.count = 7;
        this.page.total = 0;
        this.handleResize();
        this.resetDialogFullscreen();
        this.search();
        window.addEventListener('resize', this.handleResize);
    },
    watch: {
        fullscreen: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.resetDialogFullscreen();
                }
            }
        }
    }
}
</script>

<style lang="scss">
.a {
    margin: 0 auto;
    height: 100vh !important;
    max-height: 100vh !important;
}

.b {
    height: 75vh;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}

.music-history-dialog-wrapper {

    .music-history-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .music-history-head {
            text-align: center;
            padding: 0 10px;
        }

        .music-history-body {
            flex-grow: 1;
            min-width: 450px;
            min-height: 100px;
            align-content: end;

            padding: 10px
        }

        .music-history-foot {
            padding: 0 10px;
        }

    }

}

</style>
