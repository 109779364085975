<template>
    <div class="debug-view">
        <mu-select label="Normal" v-model="sound" full-width>
            <mu-option v-for="(option, index) in soundList" :key="index" :label="option.name"
                       :value="option.sound"></mu-option>
        </mu-select>
    </div>
</template>

<script>

export default {
    name: "DebugView",
    props: {
        params: {
            openDialog: false,
            description: '',
        },
    },
    data: () => {
        return {
            fullscreen: true,
            screenWidth: document.documentElement.clientWidth,
            dialogShow: false,

            dataList: [],
            soundList: [
                {
                    name: 'burn_complete',
                    sound: '/audio/burn_complete.mp3',
                },
                {
                    name: 'sent_message',
                    sound: '/audio/SentMessage.mp3',
                },
                {
                    name: 'acknowledgment_sent',
                    sound: '/audio/acknowledgment_sent.mp3',
                },
                {
                    name: 'media_handoff',
                    sound: '/audio/media_handoff.mp3',
                }
            ],
            sound: null,
        }
    },
    methods: {},
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
    watch: {},
}
</script>

<style scoped lang="scss">

</style>