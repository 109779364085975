<template>
    <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
               :fullscreen="fullscreen"
               :open.sync="params.openDialog"
               class="setting-dialog-wrapper">
        <div class="setting-container">
            <div class="setting-head">
                <div>设置</div>
            </div>

            <div class="setting-body">

                <div class="setting-row">
                    <div class="setting-item">消息提醒音效</div>
                    <div class="setting-content">
                        <mu-select label="" v-model="soundName" @change="soundChangeHandle" full-width>
                            <mu-option v-for="(option, index) in soundList" :key="index" :label="option.name"
                                       :value="option"></mu-option>
                        </mu-select>
                    </div>
                </div>

                <div class="setting-row">
                    <div class="setting-item">消息提醒音量</div>
                    <div class="setting-content">
                        <mu-slider class="message-sound-slider" color="#009688" v-model="chatMessageVolume"
                                   style="color: rgb(0, 150, 136) !important;"></mu-slider>
                    </div>
                </div>

            </div>

            <div class="setting-foot">
                <div style="display: flex; flex-direction: column;">
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
                            <mu-icon value="cancel"></mu-icon>
                        </mu-button>
                    </div>
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
                    </div>
                </div>
            </div>

            <div class="hide">
                <audio id="setting-chat-sound" :src="soundUrl" controls="" style="display: none"></audio>
            </div>
        </div>
    </mu-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Setting",
    props: {
        params: {
            openDialog: false,
            description: '',
        },
    },
    computed: {
        ...mapGetters({
            isRoot: 'isSocketRoot',
            isAdmin: 'isSocketAdmin',
        }),
        chatMessageVolume: {
            get: function () {
                return this.$store.getters.getChatNotificationVolume;
            },
            set: function (value) {
                this.$store.commit('setChatNotificationVolume', value);
                this.emitChatSoundVolumeEvent(value);
            }
        },
        chatNotificationSound: {
            get: function () {
                return this.$store.getters.getChatNotificationSound;
            },
            set: function (value) {
                this.$store.commit('setChatNotificationSound', value);
            }
        },
    },
    data: () => {
        return {
            fullscreen: true,
            screenWidth: document.documentElement.clientWidth,
            dialogShow: false,

            dataList: [],
            soundList: [
                {
                    name: '等噔噔',
                    url: '/audio/burn_complete.mp3',
                },
                {
                    name: '呜',
                    url: '/audio/SentMessage.mp3',
                },
                {
                    name: '波',
                    url: '/audio/acknowledgment_sent.mp3',
                },
                {
                    name: '气泡',
                    url: '/audio/media_handoff.mp3',
                }
            ],
            soundName: null,
            soundUrl: null,
        }
    },
    methods: {
        getScreenWidth: function () {
            return document.documentElement.clientWidth;
        },

        emitChatSoundVolumeEvent: function (volume) {
            this.$emit('chatSoundEvent', volume);
        },

        initSound: function() {
            this.soundName = this.chatNotificationSound.name;
            this.soundUrl = this.chatNotificationSound.url;
        },

        soundChangeHandle: function (data) {
            this.$store.commit('setChatNotificationSound', data);
            this.soundName = data.name;
            this.soundUrl = data.url;
            let player = document.querySelector('#setting-chat-sound');
            if (player) {
                setTimeout(() => {
                    player.play().catch(error => {
                        console.error('播放音效时出错:', error);
                    });
                }, 20);
            }
        },

        handleResize: function () {
            this.screenWidth = document.documentElement.clientWidth;
            if (this.screenWidth <= 800) {
                this.fullscreen = true;
            } else {
                this.fullscreen = false;
            }
        },

        resetDialogFullscreen: function () {
            this.dialogShow = false;
            if (this.fullscreen) {
                setTimeout(() => {
                    // let muDialogBody = document.querySelector(".mu-dialog-body");
                    // if (muDialogBody != null) {
                    //     muDialogBody.classList.add("a");
                    // }

                    // let muTable = document.querySelector("#dialogDataTable");
                    // if (muTable != null) {
                    //     muTable.classList.add("b");
                    // }

                    this.dialogShow = true;
                }, 10);
            } else {
                setTimeout(() => {
                    // let muDialogBody = document.querySelector(".mu-dialog-body");
                    // if (muDialogBody != null) {
                    //     muDialogBody.classList.remove("a");
                    // }
                    //
                    // let muTable = document.querySelector("#dialogDataTable");
                    // if (muTable != null) {
                    //     muTable.classList.remove("b");
                    // }

                    this.dialogShow = true;
                }, 10);
            }
        }
    },
    created() {
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.initSound();
        this.handleResize();
        this.resetDialogFullscreen();
        window.addEventListener('resize', this.handleResize);
    },
    watch: {
        fullscreen: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.resetDialogFullscreen();
                }
            }
        }
    }
}
</script>

<style lang="scss">
.a {
    margin: 0 auto;
    height: 100vh !important;
    max-height: 100vh !important;
}

.b {
    height: 75vh;
}

.mu-slider-fill {
    background-color: rgb(0, 150, 136) !important;
    color: rgb(0, 150, 136) !important;
}

.mu-slider-thumb {
    background-color: rgb(0, 150, 136) !important;
    color: rgb(0, 150, 136) !important;
}

.mu-slider-display-value {
    background-color: rgb(0, 150, 136) !important;
    .display-value-text {
    }
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}

.setting-dialog-wrapper {

    .setting-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .setting-head {
            text-align: center;
            padding: 0 10px;
        }

        .setting-body {
            flex-grow: 1;
            min-width: 450px;
            min-height: 100px;
            align-content: end;

            padding: 10px
        }

        .setting-foot {
            padding: 0 10px;
        }

    }

    .message-sound-slider {
        color: rgb(0, 150, 136) !important;
    }

}

.hide {
    display: none;
}

.mu-dialog {
    * {
    }
}

</style>
