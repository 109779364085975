<template>
    <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto"
               :scrollable="false"
               :fullscreen="fullscreen"
               :open.sync="params.openDialog"
               class="update-record-dialog-wrapper">
        <div class="update-record-container">
            <div class="update-record-head">
            </div>

            <div class="update-record-body">
                <ul class="update-record-ul">
                    <li v-for="(data, index) in dataList" class="update-record-li">
                        <span class="update-record-title">{{ `${data.time}` }}</span>
                        <div class="update-record-content" v-html="data.content"></div>
                    </li>
                </ul>
            </div>

            <div class="update-record-foot">
                <div style="display: flex; flex-direction: column;">
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
                            <mu-icon value="cancel"></mu-icon>
                        </mu-button>
                    </div>
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
                    </div>
                </div>
            </div>
        </div>
    </mu-dialog>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import {timeUtils} from "../utils";

export default {
    name: "UpdateRecord",
    props: {
        params: {
            openDialog: false,
            ifStatic: true,
            musicList: [],
        },
    },
    computed: {
        ...mapGetters({}),
        ...mapMutations({}),
    },
    data: () => {
        return {
            fullscreen: true,
            screenWidth: document.documentElement.clientWidth,
            tableHeight: 400,
            dialogShow: false,

            current: 1,
            limit: 10,
            pageCount: 7,

            keyword: null,
            dataList: [
                {
                    "version": "",
                    "time": "2025-02-27",
                    "content": "1.优化音量滑动条样式<br/>2.优化点歌提示",
                },
                {
                    "version": "",
                    "time": "2025-02-25",
                    "content": "1.新增分享房间功能，点击按钮一键复制房间专属链接，分享给好友即可进入房间。<br/>2.启/禁用自动重连功能优化用户体验",
                },
                {
                    "version": "2.0.12",
                    "time": "2025-02-23",
                    "content": "1.优化[周]歌单弹框样式<br/>2.支持房间分享<br/>3.保存客户端点歌历史<br/>4.斗图功能下线<br/>",
                },
                {
                    "version": "2.0.11",
                    "time": "2025-02-22",
                    "content": "1.新增消息提示音效",
                },
                {
                    "version": "2.0.10",
                    "time": "2025-02-16",
                    "content": "1.新增使用说明",
                },
                {
                    "version": "2.0.9",
                    "time": "2025-02-15",
                    "content": "1.优化聊天消息气泡样式<br/>2.修复新消息不会保持底部的问题",
                },
                {
                    "version": "2.0.8",
                    "time": "2025-02-14",
                    "content": "1.新增自动重连功能",
                },
                {
                    "version": "2.0.7",
                    "time": "2025-02-12",
                    "content": "1.房间搜索弹框中新增在线人数字段",
                },
                {
                    "version": "2.0.6",
                    "time": "2023-08-22",
                    "content": "1.优化聊天公屏样式<br/>2.修复一些问题",
                },
                {
                    "version": "2.0.5",
                    "time": "2023-08-21",
                    "content": "1.优化房间搜索弹框<br/>2.公屏消息按房间隔离<br/>3.修复一些问题",
                },
                {
                    "version": "2.0.4",
                    "time": "2023-08-18",
                    "content": "1.优化一些样式<br/>2.修复一些问题",
                },
                {
                    "version": "2.0.3",
                    "time": "2023-08-17",
                    "content": "1.多房间版本初步上线",
                },
                {
                    "version": "2.0.2",
                    "time": "2023-08-15",
                    "content": "1.新增[周]歌单",
                },
                {
                    "version": "2.0.1",
                    "time": "2023-08-10",
                    "content": "1.新增消息的过期策略",
                },
                {
                    "version": "2.0.0",
                    "time": "2023-08-03",
                    "content": "1.支持查看历史消息",
                },
                {
                    "version": "1.0.1",
                    "time": "2020-02-05",
                    "content": "1.支持房间设置默认歌单",
                },
                {
                    "version": "1.0.0",
                    "time": "2019-12-23",
                    "content": "初版发布，包含：点歌、斗图、在线聊天、管理员权限等功能",
                },
            ],
        }
    },
    methods: {

        formatterTime: function (value) {
            return timeUtils.secondsToHH_mm_ss(value)
        },

        getScreenWidth: function () {
            return document.documentElement.clientWidth;
        },

        handleResize: function () {
            this.screenWidth = document.documentElement.clientWidth;
            if (this.screenWidth <= 800) {
                this.fullscreen = true;
                // this.tableHeight = document.documentElement.clientHeight - 64 - 72;
            } else {
                this.fullscreen = false;
                // this.tableHeight = document.documentElement.clientHeight - 64 - 72 - 24 - 100;
            }
        },

        resetDialogFullscreen: function () {
            this.dialogShow = false;
            if (this.fullscreen) {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.add("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.add("b");
                    }

                    this.dialogShow = true;
                }, 10);
            } else {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.remove("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.remove("b");
                    }

                    this.dialogShow = true;
                }, 10);
            }
        }
    },
    created() {
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.handleResize();
        this.resetDialogFullscreen();
        window.addEventListener('resize', this.handleResize);
    },
    watch: {
        fullscreen: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.resetDialogFullscreen();
                }
            }
        }
    }
}
</script>

<style lang="scss">
.a {
    margin: 0 auto;
    height: 100vh !important;
    max-height: 100vh !important;
}

.b {
    height: 75vh;
}

.mu-table-body-wrapper {
    height: 100%;
}

.mu-dialog {
    min-width: 75% !important;
}

.mu-dialog .mu-dialog-body {
    max-width: 100% !important;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}

.update-record-dialog-wrapper {

    .update-record-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;

        .update-record-head {
            text-align: center;
            padding: 0 10px;
        }

        .update-record-body {
            flex-grow: 1;
            min-width: 450px;
            min-height: 100px;

            padding: 10px 30px;
            align-content: flex-start;
            overflow-y: auto;
        }

        .update-record-foot {
            padding: 0 10px;
        }

    }

}

.update-record-ul {
    .update-record-li {
        margin-bottom: 12px;

        .update-record-title {
            font-size: 20px;
            font-weight: 900;
        }

        .update-record-content {
            font-size: 14px;
        }
    }
}

</style>
