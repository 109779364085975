<template>
    <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
               :fullscreen="fullscreen"
               :open.sync="params.openDialog"
               class="">
        <div class="playlist-container">
            <div class="playlist-head">
                <mu-text-field :value="keyword"
                               @input="updateSearchKeyword"
                               @keydown.enter="search"
                               placeholder="请输入关键字搜索..."
                               color="#009688"
                               class="width-size-100" style="text-align: center"></mu-text-field>
            </div>

            <div class="playlist-body">
                <mu-data-table id="dialogDataTable"
                               style="background-color: transparent"
                               :selectable="false"
                               :hover="false"
                               :height="tableHeight"
                               :columns="columns" :data="dataList">
                    <template slot-scope="scope">
                        <td class="is-left">{{ scope.$index + 1 }}</td>

                        <td v-if="fullscreen" class="is-center">
                            <a v-if="showPickButton(scope.row.privilege)" class="search_pick_btn_m"
                               @click="pickMusic(scope.row)">点歌</a>
                            <mu-tooltip v-if="!showPickButton(scope.row.privilege)" content="当前音乐不能点播">
                                <a v-if="" class="search_pick_btn_disable_m">点歌</a>
                            </mu-tooltip>
                        </td>

                        <td class="is-left">{{ scope.row.name }}
                        </td>
                        <td class="is-center">{{ scope.row.artist }}</td>
                        <td class="is-center">{{ '《' + scope.row.album + '》' }}</td>
                        <td class="is-center">{{ formatterTime(scope.row.duration / 1000) }}</td>

                        <td v-if="!fullscreen" class="is-center">
                            <a v-if="showPickButton(scope.row.privilege)" class="search_pick_btn"
                               @click="pickMusic(scope.row)">点歌</a>
                            <mu-tooltip v-if="!showPickButton(scope.row.privilege)" content="当前音乐不能点播">
                                <a v-if="" class="search_pick_btn_disable">点歌</a>
                            </mu-tooltip>
                        </td>
<!--                        <td v-if="!fullscreen" class="is-center">-->
<!--                            <a class="search_pick_btn" v-if="!scope.row.inPlaylist"-->
<!--                               @click="appendPlaylist(scope.row)">发送</a>-->
<!--                            <mu-tooltip v-if="scope.row.inPlaylist" content="已添加到播放列表">-->
<!--                                <a v-if="" class="search_pick_btn_disable">发送</a>-->
<!--                            </mu-tooltip>-->
<!--                        </td>-->
<!--                        <td v-if="fullscreen" class="is-center">-->
<!--                            <a class="search_pick_btn_m" v-if="!scope.row.inPlaylist"-->
<!--                               @click="appendPlaylist(scope.row)">发送</a>-->
<!--                            <mu-tooltip v-if="scope.row.inPlaylist" content="已添加到播放列表">-->
<!--                                <a v-if="" class="search_pick_btn_disable_m">发送</a>-->
<!--                            </mu-tooltip>-->
<!--                        </td>-->
                    </template>
                </mu-data-table>
            </div>

            <div class="playlist-foot">
                <div style="display: flex; flex-direction: column;">
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
                            <mu-icon value="cancel"></mu-icon>
                        </mu-button>
                    </div>
                    <div v-if="fullscreen" style="margin: 0 auto;">
                        <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
                    </div>
                </div>
            </div>
        </div>
    </mu-dialog>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import {timeUtils} from "@/utils";
import {wsClient} from "@/api/socketClient";
import {JusicFactory} from "@/api/database";
import {log} from "@/utils/log";

export default {
    name: "Playlist",
    props: {
        params: {
            openDialog: false,
            ifStatic: true,
            musicList: [],
        },
    },
    computed: {
        ...mapGetters({
            isRoot: 'isSocketRoot',
            isAdmin: 'isSocketAdmin',
        }),
        ...mapMutations({}),
    },
    data: () => {
        return {
            fullscreen: true,
            screenWidth: document.documentElement.clientWidth,
            tableHeight: 400,
            dialogShow: false,

            current: 1,
            limit: 10,
            pageCount: 7,

            keyword: null,
            columns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', align: 'center'},
                {title: '专辑', name: 'album', align: 'center'},
                {title: '时长', name: 'duration', align: 'center'},
                {title: '操作', name: 'op', align: 'center'},
                // {title: '加入歌单', name: 'op', align: 'center'},
            ],
            fullScreenColumns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '操作', name: 'op', align: 'center'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', align: 'center'},
                {title: '专辑', name: 'album', align: 'center'},
                {title: '时长', name: 'duration', align: 'center'},
                // {title: '加入歌单', name: 'op', align: 'center'},
            ],
            normalColumns: [
                {title: '序号', name: 'id', width: 80, align: 'left'},
                {title: '歌曲', name: 'name', width: 200, align: 'left'},
                {title: '歌手', name: 'artist', align: 'center'},
                {title: '专辑', name: 'album', align: 'center'},
                {title: '时长', name: 'duration', align: 'center'},
                {title: '操作', name: 'op', align: 'center'},
                // {title: '加入歌单', name: 'op', align: 'center'},
            ],
            dataList: [],
            allList: [],
            page: {
                current: 1,
                limit: 10,
                count: 7,
                total: 0
            }
        }
    },
    methods: {
        initMusicList: function () {
            this.allList = this.params.musicList;
            this.dataList = this.allList;
            // 烂尾
            // this.handleSearchMusicCheckIfInPlaylist(this.allList);
        },
        updateSearchKeyword: function (value) {
            this.keyword = value;
        },
        search: function () {
            let keyword = this.keyword;
            this.dataList = this.allList.filter(item => item.name.includes(keyword));
        },
        showPickButton(value) {
            return true;
            /*
              if (Number(value.st) < 0) {
                  // 没有资源
                  return false;
              } else if (Number(value.fl) === 0) {
                  // 可能需要付费
                  return false;
              }
              return true
             */
        },
        pickMusic: function (row) {
            wsClient.music.pick(row);

            if (this.isRoot || this.isAdmin) {
                this.$toast.message(`[${row.id}]${row.name} - 已发送点歌请求`);
            } else {
                this.$toast.message(`${row.name} - 已发送点歌请求`);
            }
        },
        appendPlaylist: function (row) {
            log.debug('music.static.playlist.append', row);
            const data = {
                songId: row.id,
                songName: row.name,
                songArtist: row.artist,
                songAlbum: row.album,
                songDuration: row.duration,
                channel: 'netease',
            };
            wsClient.playlist.append(data);
            if (this.isRoot || this.isAdmin) {
                this.$toast.message(`[${row.id}]${row.name} - 已发出加入请求`);
            } else {
                this.$toast.message(`${row.name} - 已发出加入请求`);
            }
        },
        formatterTime: function (value) {
            return timeUtils.secondsToHH_mm_ss(value)
        },
        // 检测其是否在歌单中
        handleSearchMusicCheckIfInPlaylist(data) {
            if (!data) return;
            if (!Array.isArray(data)) return;

            const room = window.localStorage.getItem('ROOM');
            if (!room) return;

            let playlistRepository = JusicFactory.getDatabase(room).getPlaylistRepository();
            if (!playlistRepository) return;

            const idList = data.map(item => item.id);
            if (!idList || idList.length === 0) return;

            const _this = this;
            playlistRepository.getMapByMusicIds(idList).then(entityMap => {
                if (!entityMap || entityMap.size === 0) return;
                _this.allList.forEach(item => {
                    const entity = entityMap.get(item.id);
                    if (entity) {
                        item.inPlaylist = true;
                    }
                    _this.$nextTick(() => {
                        _this.dataList = _this.allList;
                    });
                });
                log.debug('music.static.playlist.check', data);
            })
        },

        getScreenWidth: function () {
            return document.documentElement.clientWidth;
        },

        handleResize: function () {
            this.screenWidth = document.documentElement.clientWidth;
            if (this.screenWidth <= 800) {
                this.fullscreen = true;
                this.columns = this.fullScreenColumns;
                this.tableHeight = document.documentElement.clientHeight - 64 - 72;
            } else {
                this.fullscreen = false;
                this.columns = this.normalColumns;
                this.tableHeight = document.documentElement.clientHeight - 64 - 72 - 24 - 100;
            }
        },

        resetDialogFullscreen: function () {
            this.dialogShow = false;
            if (this.fullscreen) {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.add("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.add("b");
                    }

                    this.dialogShow = true;
                }, 10);
            } else {
                setTimeout(() => {
                    let muDialogBody = document.querySelector(".mu-dialog-body");
                    if (muDialogBody != null) {
                        muDialogBody.classList.remove("a");
                    }

                    let muTable = document.querySelector("#dialogDataTable");
                    if (muTable != null) {
                        muTable.classList.remove("b");
                    }

                    this.dialogShow = true;
                }, 10);
            }
        }
    },
    created() {
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        this.initMusicList();
        this.handleResize();
        this.resetDialogFullscreen();
        window.addEventListener('resize', this.handleResize);
    },
    watch: {
        fullscreen: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.resetDialogFullscreen();
                }
            }
        }
    }
}
</script>

<style lang="scss">
.a {
    margin: 0 auto;
    height: 100vh !important;
    max-height: 100vh !important;
}

.b {
    height: 75vh;
}

.mu-table-body-wrapper {
    height: 100%;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}

.playlist-dialog-wrapper {

    .playlist-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .playlist-head {
            text-align: center;
            padding: 0 10px;
        }

        .playlist-body {
            flex-grow: 1;
            min-width: 450px;
            min-height: 100px;
            align-content: end;

            padding: 10px
        }

        .playlist-foot {
            padding: 0 10px;
        }

    }

}

</style>
