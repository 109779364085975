<template>
  <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
             :fullscreen="fullscreen"
             :open.sync="params.openDialog"
             class="">
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex">
        <mu-container>
          <mu-row>
            <mu-data-table id="dialogDataTable" style="background-color: transparent" :selectable="false" :hover="false"
                           :columns="columns" :data="dataList">
              <template slot-scope="scope">
                <td class="is-left">{{ scope.row.instruction }}</td>
                <td class="is-left">{{ scope.row.example }}</td>
                <td class="is-left">{{ scope.row.desc }}</td>
              </template>
            </mu-data-table>
          </mu-row>
        </mu-container>
      </div>
      <div style="display: flex; flex-direction: column;">
        <div v-if="fullscreen" style="margin: 0 auto;">
          <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
            <mu-icon value="cancel"></mu-icon>
          </mu-button>
        </div>
        <div v-if="fullscreen" style="margin: 0 auto;">
          <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
        </div>
      </div>
    </div>
  </mu-dialog>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "RoomSelect",
  props: {
    params: {
      openDialog: false,
      description: ''
    },

  },
    computed: {
        ...mapGetters({
            isRoot: 'isSocketRoot',
            isAdmin: 'isSocketAdmin',
        })
    },
  data: () => {
    return {
      fullscreen: true,
      screenWidth: document.documentElement.clientWidth,
      dialogShow: false,

      columns: [
        {title: '指令', name: 'instruction', width: 200, align: 'left'},
        {title: '示例', name: 'example', width: 200, align: 'left'},
        {title: '说明', name: 'desc', width: 300, align: 'left'},
      ],
      fullScreenColumns: [
        {title: '指令', name: 'instruction', width: 200, align: 'left'},
        {title: '示例', name: 'example', width: 200, align: 'left'},
        {title: '说明', name: 'desc', width: 300, align: 'left'},
      ],
      normalColumns: [
        {title: '指令', name: 'instruction', width: 200, align: 'left'},
        {title: '示例', name: 'example', width: 200, align: 'left'},
        {title: '说明', name: 'desc', width: 300, align: 'left'},
      ],
      dataList: [],
      staticDataList: [
          {
              instruction: '点歌 <歌名>',
              example: '点歌 春夏秋冬',
              desc: '跟歌名或歌曲 id 都可',
              admin: false,
          },
          {
              instruction: '投票切歌',
              example: '投票切歌',
              desc: '发出这四个字的指令即可',
              admin: false,
          },
          {
              instruction: '设置昵称 <昵称>',
              example: '设置昵称 Jay',
              desc: '',
              admin: false,
          },
          {
              instruction: 'admin <密码>',
              example: 'admin password',
              desc: '进入管理员模式',
              admin: true,
          },
          {
              instruction: '加入歌单 <歌曲id>',
              example: '加入歌单 186453',
              desc: '',
              admin: true,
          },
          {
              instruction: '置顶音乐 <歌曲id>',
              example: '置顶音乐 186453',
              desc: '将此歌曲设为下一首播放',
              admin: true,
          },
          {
              instruction: '删除音乐 <歌曲id>',
              example: '删除音乐 186453',
              desc: '将其从点播列表中移除',
              admin: true,
          },
          {
              instruction: '拉黑用户 <用户id>',
              example: '拉黑用户 p40doajb',
              desc: '禁止此用户发言',
              admin: true,
          },
          {
              instruction: '漂白用户 <用户id>',
              example: '漂白用户 p40doajb',
              desc: '取消此用户的拉黑状态',
              admin: true,
          },
          {
              instruction: '拉黑音乐 <歌曲id>',
              example: '拉黑音乐 186453',
              desc: '禁止点播此歌曲',
              admin: true,
          },
          {
              instruction: '漂白音乐 <歌曲id>',
              example: '漂白音乐 186453',
              desc: '取消此歌曲的拉黑状态',
              admin: true,
          },
      ]
    }
  },
  methods: {
    getScreenWidth: function () {
      return document.documentElement.clientWidth;
    },

    loadData: function() {
      let _this = this;
      this.staticDataList.forEach(data => {
        if (!data.admin) {
          _this.dataList.push(data);
        } else {
          if (_this.isRoot || _this.isAdmin) {
            _this.dataList.push(data);
          }
        }
      });
    },

    handleResize: function () {
      this.screenWidth = document.documentElement.clientWidth;
      if (this.screenWidth <= 800) {
        this.fullscreen = true;
        this.columns = this.fullScreenColumns;
      } else {
        this.fullscreen = false;
        this.columns = this.normalColumns;
      }
    },

    resetDialogFullscreen: function () {
      this.dialogShow = false;
      if (this.fullscreen) {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.add("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.add("b");
          }

          this.dialogShow = true;
        }, 10);
      } else {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.remove("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.remove("b");
          }

          this.dialogShow = true;
        }, 10);
      }
    }
  },
  created() {
    let _this = this;
    if (this.dataList === null || this.dataList.length === 0) {
      // this.search();
    } else {
      // console.log("-----")
    }

    this.loadData();


    // window.onresize = () => {
    //   return (() => {
    //     _this.screenWidth = document.documentElement.clientWidth;
    //     console.log(_this.screenWidth)
    //     _this.resetDialogFullscreen();
    //   })()
    // };
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize();
    this.resetDialogFullscreen();
    window.addEventListener('resize', this.handleResize);
  },
  watch: {
    // screenWidth: {
    //   handler(newVal, oldVal) {
    //     this.resetDialogFullscreen();
    //   }
    // },
    // room: {
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     if (newVal.openDialog) {
    //       this.resetDialogFullscreen();
    //     }
    //   }
    // }

    fullscreen: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetDialogFullscreen();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.a {
  margin: 0 auto;
  height: 100vh !important;
  max-height: 100vh !important;
}

.b {
  height: 75vh;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}


</style>
